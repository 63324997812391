<template lang="pug">
  v-layout.align-center.layout.px-4.pt-4.app--page-header(row)
    .page-header-left
      h3.pr-3 {{title}}
    v-breadcrumbs(divider='-')
      v-breadcrumbs-item(to='/dashboard', exact)
        v-icon(larg) home
      v-breadcrumbs-item(v-for='(item,key) in breadcrumbs', :key='key', :to="item.fullPath", exact) {{ item.meta.name }}
    v-spacer
    .page-header-right
      v-btn(icon, @click="refresh")
        v-icon.text--secondary refresh
</template>


<script>
import _ from 'lodash';
import routes from '@/router/paths';

export default {
  data () {
    return {
      title: '',
      routes: routes
    };
  },
  computed: {
    breadcrumbs: function () {
      let vm = this;
      let breadcrumbs = [];
      vm.routes.forEach(item => {
        if (item.name === vm.$route.matched[0].name) {
          if (item.children && item.children.length) {
            item.children.forEach(child => {
              if (child.meta.breadcrumb) {
                if (vm.$route.matched[0].path === child.path) {
                  child.fullPath = child.name === vm.$route.name ? vm.$route.fullPath : child.path;
                  breadcrumbs.push(child);
                  vm.title = child.meta.name || '';
                }
                if (vm.$route.name === child.name && !_.find(breadcrumbs, { name: child.name })) {
                  child.fullPath = child.name === vm.$route.name ? vm.$route.fullPath : child.path;
                  breadcrumbs.push(child);
                }
              }
            });
          } else if (vm.$route.matched[0].meta.breadcrumb) {
            vm.$route.matched[0].fullPath = vm.$route.matched[0].path;
            breadcrumbs.push(vm.$route.matched[0]);
            vm.title = vm.$route.matched[0].meta.name || '';
          }
        }
      });
      return breadcrumbs;
    },    
  },
  methods: {
    refresh () {
      location.reload();
    }
  }
};
</script>
