<template lang="pug">
v-toolbar(color='grey darken-4', fixed, dark, app)
  v-toolbar-title
    v-toolbar-side-icon(@click.stop='handleDrawerToggle').gray--text
  v-spacer
  v-menu(offset-y, origin='center center', :nudge-bottom='10', transition='scale-transition', v-if="user")
    v-btn(large, flat, slot='activator').gray--text
      | Olá, {{user.nome.split(' ').length ? user.nome.split(' ')[0] != '' ? user.nome.split(' ')[0] : user.nome.split(' ').length > 1 ? user.nome.split(' ')[1] : user.nome : user.nome}}
      v-icon(dark) arrow_drop_down
    v-list.pa-0
      v-list-tile(v-for='(item,index) in items', :to='!item.href ? { name: item.name } : null', :href='item.href', @click='item.click', ripple='ripple', :disabled='item.disabled', :target='item.target', rel='noopener', :key='index')
        v-list-tile-action(v-if='item.icon')
          v-icon {{ item.icon }}
        v-list-tile-content
          v-list-tile-title {{ item.title }}
</template>

<script>
import Util from '@/util';
export default {
  name: 'app-toolbar',
  components: {
  },
  data: () => ({
    loading: false,
    solicitacoes: [],
    items: [
      {
        icon: 'account_circle',
        name: 'MeusDados',
        title: 'Meus Dados',
        click: (e) => {
        }
      },
      {
        icon: 'fullscreen_exit',
        href: '#',
        title: 'Logout',
        click: (e) => {
          window.getApp.$emit('APP_LOGOUT');
        }
      }
    ],
  }),
  computed: {
    toolbarColor () {
      return this.$vuetify.options.extra.mainNav;
    },
    user () {
      return this.$store.getters.user;
    }
  },
  mounted () {
    let vm = this;
    // setInterval(() => {
    //   vm.getSolicitacoes();
    // }, 1000 * 60);
  },
  methods: {
    handleDrawerToggle () {
      window.getApp.$emit('APP_DRAWER_TOGGLED');
    },
    handleFullScreen () {
      Util.toggleFullScreen();
    },
  }
};
</script>
