import Vue from 'vue';
import Router from 'vue-router';
import paths from './paths';
import store from '.././store';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

Vue.use(Router);
const router = new Router({
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  routes: paths
});

router.beforeEach((to, from, next) => {
    next();
    // let permissionAccess = (!store.getters.user) || !to.meta.audience ? false : _.includes(to.meta.audience, store.getters.user.permissao) ? true : false;

    // if (store.getters.user && to.meta.public) {
    //     store.dispatch('setUser', null);
    //     next({
    //         path: "/login"
    //     });
    // } else if (to.meta.public|| permissionAccess) {
    //     next();
    // } else {
    //     next({
    //         path: "/login"
    //     });
    // }
});

router.afterEach((to, from) => {
  NProgress.done();
});




export default router;
