import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';
import echarts from 'echarts';
window.echarts = echarts;

import './theme/default.styl';

import './util/filters'

import './plugins/VueMoment'
import './plugins/vuetify'
import './plugins/vue-croppie'
import '@/plugins/VeeValidate';
import '@/plugins/VuetifyConfirm';
import '@/plugins/VueTheMask';

import Fabric from '@/components/fabric';

Vue.use(Fabric);

createPersistedState({
  key: 'vuex',
  paths: [],
  storage: window.localStorage
})(store);

Vue.prototype.$axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000
});

Vue.prototype.$axios.interceptors.request.use(config => {
    config.headers.Authorization = process.env.VUE_APP_AUTH_ADMIN;
    config.headers.Token = store.getters.user ? store.getters.user.token : null;
    config.headers.Licenca = store.getters.user ? store.getters.user.usuario_licencas[0].licenca_id : null;
    return config;
}, error => {
  return Promise.reject(error);
});

Vue.prototype.$axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error && error.response && error.response.status) {
    if (error.response.status === 401) {
      window.getApp.$emit('APP_AUTH_FAILED');
    } else if (error.response.status === 403) {
      window.getApp.$emit('APP_ACCESS_DENIED');
    } else if (error.response.status === 500) {
      window.getApp.$emit('APP_BAD_REQUEST');
    }
  }
  return Promise.reject(error);
});

Vue.config.productionTip = false;


Vue.filter('cpf_cnpj', str => {
  let output = '';
  if (str) {
    str = str.replace(/\D/g, '');
    if (str.length === 11)
      output = `${str.slice(0, 3)}.${str.slice(3, 6)}.${str.slice(6, 9)}-${str.slice(9)}`;
    else
      output = `${str.slice(0, 2)}.${str.slice(2, 5)}.${str.slice(5, 8)}/${str.slice(8, 12)}-${str.slice(12)}`;
  }
  return output;
});

Vue.filter('statusAuditoria', status => status === 1 ? 'Vigente' : status === 2 ? 'Concluída' : status === 3 ? 'Cancelada' : 'Cancelada');

Vue.filter('statusResultadoValor', status => status === 1 ? 'Aguardando Revisão' : status === 2 ? 'Válido' : status === 3 ? 'Inválido' : 'Inválido');

Vue.filter('permissaoUsuario', permissao => permissao === 0 ? 'Gerente' : permissao === 1 ? 'Auditor' : 'Outro');

new Vue({
  render: h => h(App),
  router,
  store,
  computed: {
    user() {
      return store.getters.user;
    }
  },
  data: {
    allStatusAuditoria: [
      { id: 1, nome: 'Vigente' },
      { id: 2, nome: 'Concluída' },
      { id: 3, nome: 'Cancelada' },
    ],
    bgStatusResultado: [
      { id  : 0, bgColor : '#C9302C' },
      { id  : 1, bgColor : '#ec971f'},
      { id  : 2, bgColor : '#318837' },
      { id  : 3, bgColor : '#C9302C' },
    ],
  },
  methods: {
    checkPermission(audience) {
      if (!store.getters.user) return false;
      return _.includes(audience, store.getters.user.permissao);
    },
    b64toBlob(image) {
      let block = image.split(';');
      let contentType = block[0].split(':')[1];
      let b64Data = block[1].split(',')[1];
      let sliceSize = 512;
      let byteCharacters = atob(b64Data);
      let byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);
        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      let blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
  },
}).$mount('#app')
