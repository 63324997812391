const Menu =  [
  {
    title: 'Dashboard',
    icon: 'dashboard',
    name: 'Dashboard',
    audience: [0, 1],
  },
  {
    title: 'Auditados',
    icon: 'verified_user',
    name: 'Auditados',
    audience: [0],
  },
  {
    title: 'Auditorias',
    icon: 'folder',
    name: 'Auditorias',
    audience: [0, 1],
  },
  {
    title: 'Categorias de Feedbacks',
    icon: 'feedback',
    name: 'CategoriasFeedbacks',
    audience: [0],
  },
  {
    title: 'Feedbacks',
    icon: 'feedback',
    name: 'Feedbacks',
    audience: [0],
  },
//   {
//     title: 'Categorias de Questões',
//     icon: 'insert_drive_file',
//     name: 'CategoriasQuestoes',
//     audience: [0],
//   },
  {
    title: 'Questionários',
    icon: 'description',
    name: 'Questionarios',
    audience: [0],
  },
  {
    title: 'Diagnóstico Rápido',
    icon: 'flash_on',
    name: 'DiagnosticoRapido',
    audience: [0],
  },
  {
    title: 'Tipos de Identificadores',
    icon: 'playlist_add_check',
    name: 'TiposIdentificadores',
    audience: [0],
  },
  {
    title: 'Tipos de Questionários',
    icon: 'playlist_add_check',
    name: 'TiposQuestionarios',
    audience: [0],
  },
  {
    title: 'Usuários',
    icon: 'people_alt',
    name: 'Usuarios',
    audience: [0],
  },
  {
    title: 'Notificações',
    icon: 'notifications',
    name: 'Notificacoes',
    audience: [0],
  },
  {
    title: 'Relatórios',
    icon: 'analytics',
    name: 'Relatorios',
    audience: [0],
  },
];
// reorder menu

// Menu.sort((x, y) => {
//     let textA = x.title.toUpperCase();
//     let textB = y.title.toUpperCase();
//     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
// });

Menu.forEach((item) => {
  if (item.items) {
    item.items.sort((x, y) => {
      let textA = x.title.toUpperCase();
      let textB = y.title.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }
});

export default Menu;
