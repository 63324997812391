import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
  actions: {
    setUser({ commit }, value) {
      commit('setUser', value);
    },
  },
  mutations: {
    setUser(state, value) {
      state.user = value;
    }
  },
  plugins: [
    createPersistedState(),
  ],
  strict: process.env.NODE_ENV !== 'production'
});
