import Vue from 'vue';
import VuetifyConfirm from 'vuetify-confirm';
import Vuetify from 'vuetify';

Vue.use(VuetifyConfirm, {
  Vuetify,
  buttonTrueText: 'Sim',
  buttonFalseText: 'Não',
  buttonTrueColor: 'bt-primary',
  color: 'primary',
  icon: 'warning',
  title: 'Cuidado',
  width: 350,
  property: '$confirm'
});

export default Vue;