<template lang="pug">
  v-navigation-drawer#appDrawer(:mini-variant.sync='mini', fixed, :dark='$vuetify.dark', app, v-model='drawer', width='260')
    v-toolbar(color='grey darken-4', dark)
      img(v-bind:src=' $root && $root.user && $root.user.usuario_licencas && $root.user.usuario_licencas[0] && $root.user.usuario_licencas[0].licenca && $root.user.usuario_licencas[0].licenca.logo ? $root.user.usuario_licencas[0].licenca.logo : logo', height='50', alt='Automatic 4')
    vue-perfect-scrollbar.drawer-menu--scroll(:settings='scrollSettings')
      v-list(dense, expand)
        template(v-for='(item, i) in menus', v-if="$root.checkPermission(item.audience)")
          // group with subitems
          v-list-group(v-if='item.items', :key='item.name', :group='item.group', :prepend-icon='item.icon', no-action='no-action')
            v-list-tile(slot='activator', ripple='ripple')
              v-list-tile-content
                v-list-tile-title {{ item.title }}
            template(v-for='(subItem, i) in item.items')
              // sub group
              v-list-group(v-if='subItem.items', :key='subItem.name', :group='subItem.group', sub-group='sub-group')
                v-list-tile(slot='activator', ripple='ripple')
                  v-list-tile-content
                    v-list-tile-title {{ subItem.title }}
                v-list-tile(v-for='(grand, i) in subItem.children', :key='i', :to='genChildTarget(item, grand)', :href='grand.href', ripple='ripple')
                  v-list-tile-content
                    v-list-tile-title {{ grand.title }}
              // child item
              v-list-tile(v-else, :key='i', :to='genChildTarget(item, subItem)', :href='subItem.href', :disabled='subItem.disabled', :target='subItem.target', ripple='ripple')
                v-list-tile-content
                  v-list-tile-title
                    span {{ subItem.title }}
                // <v-circle class="white--text pa-0 circle-pill" v-if="subItem.badge" color="red" disabled="disabled">{{ subItem.badge }}</v-circle>
                v-list-tile-action(v-if='subItem.action')
                  v-icon(:class="[subItem.actionClass || 'success--text']") {{ subItem.action }}
          v-subheader(v-else-if='item.header', :key='i') {{ item.header }}
          v-divider(v-else-if='item.divider', :key='i')
          // top-level link
          v-list-tile(v-else, :to='!item.href ? { name: item.name } : null', :href='item.href', ripple='ripple', :disabled='item.disabled', :target='item.target', rel='noopener', :key='item.name')
            v-list-tile-action(v-if='item.icon')
              v-icon.font-weight-bold  {{ item.icon }}
            v-list-tile-content
              v-list-tile-title.font-weight-medium {{ item.title }}
            // <v-circle class="white--text pa-0 chip--x-small" v-if="item.badge" :color="item.color || 'primary'" disabled="disabled">{{ item.badge }}</v-circle>
            v-list-tile-action(v-if='item.subAction')
              v-icon.success--text {{ item.subAction }}
            // <v-circle class="caption blue lighten-2 white--text mx-0" v-else-if="item.chip" label="label" small="small">{{ item.chip }}</v-circle>
</template>

<script>
import menu from '@/menu';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import logo from '@/assets/images/logo-white.png';

export default {
  name: 'app-drawer',
  components: {
    VuePerfectScrollbar,
  },
  props: {
    expanded: {
      type: Boolean,
      default: true
    },
  },
  data: () => ({
    loading: false,
    mini: false,
    drawer: true,
    menus: menu,
    logo: logo,
    scrollSettings: {
      maxScrollbarLength: 160
    }    
  }),
  computed: {
    computeGroupActive () {
      return true;
    },
    sideToolbarColor () {
      return this.$vuetify.options.extra.sideNav;
    }    
  },
  created () {
    window.getApp.$on('APP_DRAWER_TOGGLED', () => {
      this.drawer = (!this.drawer);
    });
  },
  

  methods: {
    genChildTarget (item, subItem) {
      if (subItem.href) return;
      if (subItem.component) {
        return {
          name: subItem.component,
        };
      }
      return { name: `${item.group}/${(subItem.name)}` };
    },
  }
};
</script>


<style lang="scss">

#appDrawer {
  overflow: hidden;
  .drawer-menu--scroll {
    height: calc(100vh - 48px);
    overflow: auto;
  }
}
  

</style>
