import Vue from "vue";
import Vuetify from "vuetify";
import pt from "vuetify/es5/locale/pt";

Vue.use(Vuetify, {
  lang: {
    locales: { pt },
    current: "pt",
  },
  theme: {
    primary: "#F9A825",
    original_color: "#F9D865",
    "primary-color": process.env.VUE_APP_PRIMARY_COLOR,
    "bt-primary": "#1976D2",
    danger: "#f44336",
    warning: "#ff9800",
    info: "#056CA0",
    success: "#4caf50",
    "lb-danger": "#D50000",
    "lb-success": "#2BB713",
    "lb-warning": "#f0ad4e",
    "lb-info": "#5CBBF6",
    "lb-primary": "#2E73C5",
    ticket: "#fffcdd",
    chart_color_yellow: "#F5C343",
    chart_color_green: "#4caf50",
    chart_color_red: "#f44336",
    chart_color_light_yellow: "#FCFABC",
  },
  options: {
    extra: {
      mainToolbar: {
        color: "primary",
      },
      sideToolbar: {},
      sideNav: "primary",
      mainNav: "primary lighten-1",
      bodyBg: "",
    },
  },
});

export default Vue;
