export default [
    {
        path: '*',
        meta: {
            public: true,
        },
        redirect: {
            path: '/login'
        }
    },  
    {
        path: '/login',
        meta: {
            public: true,
        },
        name: 'Login',
        component: () => import(`@/pages/Login.vue`)
    },
    {
        path: '/401',
        meta: {
            public: true,
        },
        name: '401',
        component: () => import(`@/pages/401.vue`)
    },
    {
        path: '/admin',
        meta: { },
        name: 'Root',
        redirect: {
            name: 'Login'
        }
    },
    {
        path: '/dashboard',
        meta: { breadcrumb: true, name: 'Dashboard', audience: [0, 1] },
        name: 'Dashboard',
        component: () => import(`@/pages/Dashboard.vue`)
    },
    {
        path: '/meus-dados',
        meta: { breadcrumb: true, name: 'Meus Dados', audience: [0, 1] },
        name: 'MeusDados',
        component: () => import(`@/pages/MeusDados.vue`)
    },
    {
        path: '/licenca',
        name: 'Licencas',
        component: { template: '<router-view/>' },
        redirect: {
            path: '/licenca'
        },
        children: [
            {
                path: '/licenca',
                name: 'LicencaLista',
                meta: { breadcrumb: true, name: 'Licenças', audience: [0, 1]},
                component: () => import(`@/pages/licenca/Lista.vue`),
            },
            {
                path: '/licenca/cadastro',
                name: 'LicencaCadastro',
                meta: { breadcrumb: true, name: 'Cadastro', audience: [0, 1] },
                component: () => import(`@/pages/licenca/Cadastro.vue`),
            },
            {
                path: '/licenca/cadastro/:id',
                name: 'LicencaEditar',
                meta: { breadcrumb: true, name: 'Editar', audience: [0, 1] },
                component: () => import(`@/pages/licenca/Cadastro.vue`),
            },
        ]
    },
  {
    path: '/tipo-questionario',
    name: 'TiposQuestionarios',
    component: { template: '<router-view/>' },
    redirect: {
      path: '/tipo-questionario'
    },
    children: [
      {
        path: '/tipo-questionario',
        name: 'TipoQuestionarioLista',
        meta: { breadcrumb: true, name: 'Tipos de Questionário', audience: [0, 1], },
        component: () => import(`@/pages/tipo-questionario/Lista.vue`),
      },
      {
        path: '/tipo-questionario/cadastro',
        name: 'TipoQuestionarioCadastro',
        meta: { breadcrumb: true, name: 'Cadastro', audience: [0, 1], },
        component: () => import(`@/pages/tipo-questionario/Cadastro.vue`),
      },
      {
        path: '/tipo-questionario/cadastro/:id',
        name: 'TipoQuestionarioEditar',
        meta: { breadcrumb: true, name: 'Editar', audience: [0, 1], },
        component: () => import(`@/pages/tipo-questionario/Cadastro.vue`),
      },
    ]
  },
  {
    path: '/tipo-identificador',
    name: 'TiposIdentificadores',
    component: { template: '<router-view/>' },
    redirect: {
      path: '/tipo-identificador'
    },
    children: [
      {
        path: '/tipo-identificador',
        name: 'TipoIdentificadorLista',
        meta: { breadcrumb: true, name: 'Tipos de Identificadores', audience: [0, 1], },
        component: () => import(`@/pages/tipo-identificador/Lista.vue`),
      },
      {
        path: '/tipo-identificador/cadastro',
        name: 'TipoIdentificadorCadastro',
        meta: { breadcrumb: true, name: 'Cadastro', audience: [0, 1], },
        component: () => import(`@/pages/tipo-identificador/Cadastro.vue`),
      },
      {
        path: '/tipo-identificador/cadastro/:id',
        name: 'TipoIdentificadorEditar',
        meta: { breadcrumb: true, name: 'Editar', audience: [0, 1], },
        component: () => import(`@/pages/tipo-identificador/Cadastro.vue`),
      },
    ]
  },
  {
    path: '/feedback',
    name: 'Feedbacks',
    component: { template: '<router-view/>' },
    redirect: {
      path: '/feedback'
    },
    children: [
      {
        path: '/feedback',
        name: 'FeedbackLista',
        meta: { breadcrumb: true, name: 'Feedbacks', audience: [0, 1], },
        component: () => import(`@/pages/feedback/Lista.vue`),
      },
      {
        path: '/feedback/cadastro',
        name: 'FeedbackCadastro',
        meta: { breadcrumb: true, name: 'Cadastro', audience: [0, 1], },
        component: () => import(`@/pages/feedback/Cadastro.vue`),
      },
      {
        path: '/feedback/cadastro/:id',
        name: 'FeedbackEditar',
        meta: { breadcrumb: true, name: 'Editar', audience: [0, 1], },
        component: () => import(`@/pages/feedback/Cadastro.vue`),
      },
    ]
  },
  {
    path: '/categoria-feedback',
    name: 'CategoriasFeedbacks',
    component: { template: '<router-view/>' },
    redirect: {
      path: '/categoria-feedback'
    },
    children: [
      {
        path: '/categoria-feedback',
        name: 'CategoriaFeedbackLista',
        meta: { breadcrumb: true, name: 'Categorias de Feedbacks', audience: [0, 1], },
        component: () => import(`@/pages/categoria-feedback/Lista.vue`),
      },
      {
        path: '/categoria-feedback/cadastro',
        name: 'CategoriaFeedbackCadastro',
        meta: { breadcrumb: true, name: 'Cadastro', audience: [0, 1], },
        component: () => import(`@/pages/categoria-feedback/Cadastro.vue`),
      },
      {
        path: '/categoria-feedback/cadastro/:id',
        name: 'CategoriaFeedbackEditar',
        meta: { breadcrumb: true, name: 'Editar', audience: [0, 1], },
        component: () => import(`@/pages/categoria-feedback/Cadastro.vue`),
      },
    ]
  },
  {
    path: '/categoria-questao',
    name: 'CategoriasQuestoes',
    component: { template: '<router-view/>' },
    redirect: {
      path: '/categoria-questao'
    },
    children: [
      {
        path: '/categoria-questao',
        name: 'CategoriaQuestaoLista',
        meta: { breadcrumb: true, name: 'Categorias de Questões', audience: [0, 1], },
        component: () => import(`@/pages/categoria-questao/Lista.vue`),
      },
      {
        path: '/categoria-questao/cadastro',
        name: 'CategoriaQuestaoCadastro',
        meta: { breadcrumb: true, name: 'Cadastro', audience: [0, 1], },
        component: () => import(`@/pages/categoria-questao/Cadastro.vue`),
      },
      {
        path: '/categoria-questao/cadastro/:id',
        name: 'CategoriaQuestaoEditar',
        meta: { breadcrumb: true, name: 'Editar', audience: [0, 1], },
        component: () => import(`@/pages/categoria-questao/Cadastro.vue`),
      },
    ]
  },


  {
    path: '/notificacao',
    name: 'Notificacoes',
    component: { template: '<router-view/>' },
    redirect: {
      path: '/notificacao'
    },
    children: [
      {
        path: '/notificacao',
        name: 'NotificacaoLista',
        meta: { breadcrumb: true, name: 'Notificações', audience: [0, 1], },
        component: () => import(`@/pages/notificacao/Lista.vue`),
      },
      {
        path: '/notificacao/cadastro',
        name: 'NotificacaoCadastro',
        meta: { breadcrumb: true, name: 'Cadastro', audience: [0, 1], },
        component: () => import(`@/pages/notificacao/Cadastro.vue`),
      },
      {
        path: '/notificacao/cadastro/:id',
        name: 'NotificacaoEditar',
        meta: { breadcrumb: true, name: 'Editar', audience: [0, 1], },
        component: () => import(`@/pages/notificacao/Cadastro.vue`),
      },
    ]
  },


  {
    path: '/questionario',
    name: 'Questionarios',
    component: { template: '<router-view/>' },
    redirect: {
      path: '/questionario'
    },
    children: [
      {
        path: '/questionario',
        name: 'QuestionarioLista',
        meta: { breadcrumb: true, name: 'Questionários', audience: [0, 1], },
        component: () => import(`@/pages/questionario/Lista.vue`),
      },
      {
        path: '/questionario/cadastro',
        name: 'QuestionarioCadastro',
        meta: { breadcrumb: true, name: 'Cadastro', audience: [0, 1], },
        component: () => import(`@/pages/questionario/Cadastro.vue`),
      },
      {
        path: '/questionario/cadastro/:id',
        name: 'QuestionarioEditar',
        meta: { breadcrumb: true, name: 'Editar', audience: [0, 1], },
        component: () => import(`@/pages/questionario/Cadastro.vue`),
      },
    ]
  },
  {
    path: '/auditado',
    name: 'Auditados',
    component: { template: '<router-view/>' },
    redirect: {
      path: '/auditado'
    },
    children: [
      {
        path: '/auditado',
        name: 'AuditadoLista',
        meta: { breadcrumb: true, name: 'Auditados', audience: [0, 1], },
        component: () => import(`@/pages/auditado/Lista.vue`),
      },
      {
        path: '/auditado/cadastro',
        name: 'AuditadoCadastro',
        meta: { breadcrumb: true, name: 'Cadastro', audience: [0, 1], },
        component: () => import(`@/pages/auditado/Cadastro.vue`),
      },
      {
        path: '/auditado/detalhes/:id',
        name: 'AuditadoDetalhes',
        meta: { breadcrumb: true, name: 'Detalhes', audience: [0, 1], },
        component: () => import(`@/pages/auditado/Detalhes.vue`),
      },
      {
          path: '/auditado/detalhes/:id/auditoria/cadastro/:idAuditoria',
          name: 'AuditadoAuditoria',
          meta: { breadcrumb: true, name: 'Auditoria', audience: [0, 1], },
          component: () => import(`@/pages/auditado/Auditoria.vue`),
      },
      {
          path: '/auditado/detalhes/:id/auditoria/cadastro',
          name: 'AuditadoAuditoriaCadastro',
          meta: { breadcrumb: true, name: 'Auditoria', audience: [0, 1], },
          component: () => import(`@/pages/auditado/Auditoria.vue`),
      },
    ]
  },
  {
    path: '/usuario',
    name: 'Usuarios',
    component: { template: '<router-view/>' },
    redirect: {
      path: '/usuario'
    },
    children: [
      {
        path: '/usuario',
        name: 'UsuarioLista',
        meta: { breadcrumb: true, name: 'Usuários', audience: [0, 1], },
        component: () => import(`@/pages/usuario/Lista.vue`),
      },
      {
        path: '/usuario/cadastro',
        name: 'UsuarioCadastro',
        meta: { breadcrumb: true, name: 'Cadastro', audience: [0, 1], },
        component: () => import(`@/pages/usuario/Cadastro.vue`),
      },
      {
        path: '/usuario/cadastro/:id',
        name: 'UsuarioEditar',
        meta: { breadcrumb: true, name: 'Editar', audience: [0, 1], },
        component: () => import(`@/pages/usuario/Cadastro.vue`),
      },
    ]
  },
  {
    path: '/auditoria',
    name: 'Auditorias',
    component: { template: '<router-view/>' },
    redirect: {
      path: '/auditoria'
    },
    children: [
      {
        path: '/auditoria',
        name: 'AuditoriaLista',
        meta: { breadcrumb: true, name: 'Auditorias', audience: [0, 1], },
        component: () => import(`@/pages/auditoria/Lista.vue`),
      },
      {
        path: '/auditoria/cadastro',
        name: 'AuditoriaCadastro',
        meta: { breadcrumb: true, name: 'Cadastro', audience: [0, 1], },
        component: () => import(`@/pages/auditoria/Cadastro.vue`),
      },
      {
        path: '/auditoria/cadastro/:id',
        name: 'AuditoriaEditar',
        meta: { breadcrumb: true, name: 'Editar', audience: [0, 1], },
        component: () => import(`@/pages/auditoria/Cadastro.vue`),
      },
    ]
  },
  {
    path: '/resultado',
    name: 'Resultado',
    component: { template: '<router-view/>' },
    redirect: {
      path: '/resultado'
    },
    children: [
      {
        path: '/auditoria/resultado/:id',
        name: 'ResultadosLista',
        meta: { breadcrumb: true, name: 'Resultados', audience: [0, 1], },
        component: () => import(`@/pages/resultado/Lista.vue`),
      },
    ]
  },
  {
    path: "/relatorios",
    name: "Relatorios",
    component: { template: "<router-view/>" },
    redirect: {
      path: "/relatorios"
    },
    children: [
      {
        path: "/relatorios",
        name: "RelatoriosLista",
        meta: { breadcrumb: false, name: "Relatorios" },
        component: () => import(`@/pages/relatorios/Lista.vue`)
      },
    ]
  },
  {
    path: '/diagnostico-rapido',
    name: 'DiagnosticoRapido',
    component: { template: '<router-view/>' },
    redirect: {
      path: '/diagnostico-rapido'
    },
    children: [
      {
        path: '/diagnostico-rapido',
        name: 'DiagnosticoRapidoLista',
        meta: { breadcrumb: true, name: 'Diagnóstico Rápido', audience: [0, 1], },
        component: () => import(`@/pages/diagnostico-rapido/Lista.vue`),
      },
      {
        path: '/diagnostico-rapido/cadastro',
        name: 'DiagnosticoRapidoCadastro',
        meta: { breadcrumb: true, name: 'Cadastro', audience: [0, 1], },
        component: () => import(`@/pages/diagnostico-rapido/Cadastro.vue`),
      },
      {
        path: '/diagnostico-rapido/cadastro/:id',
        name: 'DiagnosticoRapidoEditar',
        meta: { breadcrumb: true, name: 'Editar', audience: [0, 1], },
        component: () => import(`@/pages/diagnostico-rapido/Cadastro.vue`),
      },
    ]
  },
  {
    path: '/politica',
    meta: {
      public: true,
    },
    name: 'Politica',
    component: () => import(
      `@/pages/Politica.vue`
    )
  },
  {
    path: '/diagnostico-rapido/questionario/:uuid',
    meta: {
      public: true,
    },
    name: 'DiagnosticoRapidoPubic',
    component: () => import(
      `@/pages/diagnostico-rapido/Questionario.vue`
    )
  },
  {
    path: '/termos',
    meta: {
      public: true,
    },
    name: 'Termos',
    component: () => import(
      `@/pages/Termos.vue`
    )
  },
];