<template lang="pug">
  #appRoot
    template(v-if='!$route.meta.public')
      v-app#inspire.app
        app-drawer.app--drawer
        app-toolbar.app--toolbar
        v-content
          // Page Header
          page-header(v-if='$route.meta.breadcrumb')
          .page-wrapper
            router-view
          // App Footer
          v-footer.white.pa-3.app--footer(height='auto')
            span.caption AUTOMATIC 4 © {{ new Date().getFullYear() }}
            v-spacer
            span.caption.mr-1 Desenvolvido por 
              a(href="http://labcinco.com", target="_blank", style="text-decoration:none;").font-weight-bold.black--text Lab Cinco
        // Go to top
        app-fab
    template(v-else)
      transition
        keep-alive
          router-view
    v-snackbar(:timeout='3000', top, right, :color='snackbar.color', v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark, flat, @click.native='snackbar.show = false', icon)
        v-icon close
</template>

<script>
import AppDrawer from '@/components/AppDrawer';
import AppToolbar from '@/components/AppToolbar';
import AppFab from '@/components/AppFab';
import PageHeader from '@/components/PageHeader';
import menu from '@/menu';
import AppEvents from  './event';

export default {
  components: {
    AppDrawer,
    AppToolbar,
    AppFab,
    PageHeader,
  },
  data: () => ({
    loading: false,
    expanded: true,
    rightDrawer: false,
    snackbar: {
      show: false,
      text: '',
      color: '',
    }
  }),
  computed: {
    user () {
      return this.$store.getters.user;
    }
  },
  watch: {
    $route (route) {
      window.getApp.$emit('APP_TITLE', route.meta.name || null);
      if ((route.path.indexOf('/politica') > -1 || route.path.indexOf('/termos') > -1 || route.path.indexOf('/diagnostico-rapido/questionario') > -1) && !this.$root.user) { 
        window.getApp.$emit("APP_CLIENT");
      } else {
        if (route.meta.public && this.user) {
          this.$router.push({ path: '/dashboard' });
        } else if (!route.meta.public && !this.user) {
          this.$router.push({ path: '/login' });
        }
      }
    }
  },
  created () {
    let vm = this;
    AppEvents.forEach(item => {
      vm.$on(item.name, item.callback);
    });
    window.getApp = vm;
  },
  methods: {
    
  },
}
</script>

<style lang="scss">
.setting-fab{
  top:50%!important; 
  right:0;
  border-radius:0  
} 
.page-wrapper{
  min-height:calc(100vh - 64px - 50px - 81px );  
}
.v-select__selections {
  cursor: pointer
}

</style>
