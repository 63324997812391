import Vue from 'vue'

Vue.filter('status', value => {
  if (value === 0) {
    return 'Não Enviada';
  } else if (value === 1) {
    return 'Enviada';
  }
})

Vue.filter('tipo', value => {
  if (value === 0) {
    return 'Instantânea';
  } else if (value === 1) {
    return 'Programada';
  }
})

Vue.filter('descricao', value => {
  return value.length > 50 ? value.substr(0, 50) + '...' : value;
})

export default Vue