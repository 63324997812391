export default [
  {
    name: 'APP_LOGIN_SUCCESS',
    callback: function (e) {
      this.$router.push({ path: '/dashboard' });
    }
  },
  {
    name: 'APP_ALERT',
    callback: function (e) {
      this.snackbar = {
        show: true,
        color: e.color,
        text: e.text
      };
    }
  },
  {
    name: 'APP_LOGOUT',
    callback: function (e) {
      this.snackbar = {
        show: true,
        color: 'green',
        text: 'Logout efetuado!'
      };
      this.$store.dispatch('setUser', null);
      this.$router.push({ path: '/login' });
    }
  },
  {
    name: 'APP_AUTH_FAILED',
    callback: function (e) {
      this.snackbar = {
        show: true,
        color: 'red',
        text: 'Login ou senha inválidos!'
      };
      this.$store.dispatch('setUser', null);
    }
  },
  {
    name: 'APP_ACCESS_DENIED',
    callback: function (e) {
      this.snackbar = {
        show: true,
        color: 'red',
        text: 'Credenciais inválidas!'
      };
      this.$router.push('/login');
      this.$store.dispatch('setUser', null);
    }
  },
  {
    name: 'APP_BAD_REQUEST',
    // @error api response data
    callback: function (msg) {
      this.snackbar = {
        show: true,
        color: 'red',
        text: 'Ocorreu um erro de conexão com o servidor. Tente novamente!'
      };
    }
  },
  {
    name: 'APP_NOT_FOUND',
    // @error api response data
    callback: function (msg) {
      this.$router.push('/404');
    }
  },
];
